import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { isDefined } from '@proman/utils';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { UserResolver } from '../app-resolvers';
import { ToastService } from '@proman/services/toast.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { Router } from '@angular/router';
import { CurrUser } from '@proman/interfaces/object-interfaces';

@Injectable({
    providedIn: 'root'
})
export class GuardsEmployeeService implements CanActivate {
    currUser: CurrUser;
    constructor(
        private PromanState: PromanStateService,
        private Toast: ToastService,
        private Router: Router,
        private store: Store,
        @Inject(UserResolver) private userResolver: UserResolver,
    ) {
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
    ): Promise<boolean> {

        const data = route.data;

        await this.userResolver.resolve();

        this.store.select(getCurrUser).subscribe((value) => {
            this.currUser = value;
        })

        const isAllowed = isDefined(data.employeeOnly) ? this.currUser.isEmployee: true;
        if (!isAllowed) {
            const errorMsg = `Employees only`;
            console.warn(errorMsg);
            this.Toast.pop('error', errorMsg);
            if (data.redirect) this.PromanState.to(data.redirect);
            if (this.Router.url.match('/')) {
                this.PromanState.go();
            }

        }
        return isAllowed;

    }
}
